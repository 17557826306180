<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      주문 및 결제하기<router-link to="" @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_33">
      <div class="sect_cont">
        <div class="box" v-if="!isChange">
          <h4 class="common">
            주문하신 분<router-link to="" class="black" @click.native="change"
              >변경하기</router-link
            >
          </h4>
          <div class="cont_box" v-if="members">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>이름</td>
                  <td>{{ members.user_name }}</td>
                </tr>
                <tr>
                  <td>전화번호</td>
                  <td>{{ members.cellphone }}</td>
                </tr>
                <tr>
                  <td>램ID</td>
                  <td>{{ members.userid }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 주문하신분 변경 -->
        <div class="box" v-if="isChange">
          <h4 class="common">
            주문하신 분<router-link to="" class="gray" @click.native="change"
              >취소</router-link
            ><router-link to="" class="black" @click.native="updateInfo"
              >수정완료</router-link
            >
          </h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr class="chg_1">
                  <td>이름</td>
                  <td>
                    <input type="text" name="tmp_name" maxlength="10" />
                  </td>
                </tr>
                <tr class="chg_2">
                  <td>전화번호</td>
                  <td :style="{ position: 'relative' }">
                    <input
                      type="text"
                      name="cellphone"
                      placeholder="휴대폰번호 입력"
                      maxlength="11"
                      v-model="cellphoneCk"
                      @keypress.enter="getAuthNumber"
                    />
                    <div class="timer" v-if="isAuthNumber">
                      <span ref="timer_min">2</span>
                      <span>:</span>
                      <span ref="timer_sec">59</span>
                    </div>
                    <router-link to="" @click.native="getAuthNumber"
                      >인증번호 전송</router-link
                    >
                  </td>
                </tr>
                <tr class="chg_1">
                  <td>인증번호</td>
                  <td>
                    <input
                      type="text"
                      name="tmp_authnumber"
                      maxlength="6"
                      v-model="authNumberCk"
                      @input="setNumber($event)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>램ID</td>
                  <td>{{ members.userid }}</td>
                </tr>
              </tbody>
            </table>
            <div class="info_txt">
              위 내용을 수정 완료하면 수정된 정보에 따라 회원 정보 역시
              변경됩니다.
            </div>
          </div>
        </div>

        <div class="box" v-if="!isShippListView">
          <h4 class="common">
            배송지<router-link to="" class="black" @click.native="changeAddr"
              >변경하기</router-link
            >
          </h4>
          <div class="cont_box" v-if="members">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>받는 분</td>
                  <td>
                    {{
                      members.default_address.user_name
                        ? members.default_address.user_name
                        : "받는분 없음"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>전화번호</td>
                  <td>
                    {{
                      members.default_address.phone
                        ? members.default_address.phone
                        : "전화번호 없음"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>휴대폰번호</td>
                  <td>
                    {{
                      members.default_address.cellphone
                        ? members.default_address.cellphone
                        : "휴대폰번호 없음"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>주소</td>
                  <td>
                    ({{ members.default_address.zipcode }})
                    {{ members.default_address.address }}
                    {{ members.default_address.address_detail }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--배송지 변경-->
        <div class="box" v-if="isShippListView">
          <h4 class="common">
            배송지<router-link to="" class="gray" @click.native="changeAddr"
              >취소</router-link
            ><router-link to="" class="black" @click.native="addressChange"
              >변경완료</router-link
            >
          </h4>
          <div class="address_box">
            <div class="add_address">
              <router-link to="" @click.native="goRegShippAddr"
                >배송지 새로 등록하기</router-link
              >
            </div>
            <ul class="add_tab">
              <li class="on">
                <router-link to="" @click.native="changeShippAddress(1, $event)"
                  >등록된 배송지</router-link
                >
              </li>
              <li>
                <router-link to="" @click.native="changeShippAddress(2, $event)"
                  >최근 사용한 배송지</router-link
                >
              </li>
            </ul>
            <div class="add_sel" v-if="isShippList">
              <div
                class="sel_box"
                v-for="(addr, index) in paymentData.shipp_address_list"
                :key="`default_${index}`"
              >
                <h5>
                  <label
                    ><input
                      type="radio"
                      name="address"
                      :checked="addr.default === 'Y'"
                      :data-type="1"
                      :data-index="index"
                    />{{ addr.address_description }}</label
                  ><span v-if="addr.default === 'Y'">기본배송지</span>
                </h5>
                <div>
                  <p>
                    {{ addr.recipient_address }}
                    {{ addr.recipient_address_detail }} ({{
                      addr.recipient_zipcode
                    }})
                  </p>
                  <p>
                    {{ addr.recipient_user_name }}
                    {{ addr.recipient_cellphone }}
                    {{
                      addr.recipient_phone ? `/ ${addr.recipient_phone}` : ""
                    }}
                  </p>
                </div>
              </div>
              <div
                class="sel_box none"
                v-if="
                  !paymentData.shipp_address_list ||
                  paymentData.shipp_address_list.length <= 0
                "
              >
                <div>등록된 배송지 정보가 없습니다.</div>
              </div>
            </div>
            <div class="add_sel" v-else>
              <div
                class="sel_box"
                v-for="(addr, idx) in latestAddressList"
                :key="`lastest_${idx}`"
              >
                <h5>
                  <label
                    ><input
                      type="radio"
                      name="address"
                      :data-type="2"
                      :data-index="idx"
                    />{{ addr.recipient_user_name }}</label
                  >
                </h5>
                <div>
                  <p>
                    {{ addr.recipient_address }}
                    {{ addr.recipient_address_detail }} ({{
                      addr.recipient_zipcode
                    }})
                  </p>
                  <p>
                    {{ addr.recipient_user_name }}
                    {{ addr.recipient_cellphone }}
                  </p>
                </div>
              </div>
              <div class="sel_box none" v-if="latestAddressList.length <= 0">
                <div>최근 사용한 배송지 정보가 없습니다.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <h4>배송 메모 입력하기</h4>
          <div class="cont_box">
            <select name="ship_message" @change="shipMessage($event)">
              <option value="">요청사항을 직접 입력합니다.</option>
              <option value="문 옆에 두세요.">문 옆에 두세요.</option>
              <option value="문 옆에 두고 초인종을 눌러주세요.">
                문 옆에 두고 초인종을 눌러주세요.
              </option>
              <option value="배송 전에 전화나 문자 주세요.">
                배송 전에 전화나 문자 주세요.
              </option>
              <option value="부재 시 경비실에 맡겨주세요.">
                부재 시 경비실에 맡겨주세요.
              </option>
              <option value="부재 시 전화나 문자 주세요.">
                부재 시 전화나 문자 주세요.
              </option>
              <option
                v-for="(msg, idx) in paymentData.lately_msg"
                :key="idx"
                :value="msg.ship_message"
              >
                (최근){{ msg.ship_message }}
              </option>
            </select>
            <div class="textarea" v-if="IsShipMsg">
              <textarea v-model="memoContent"></textarea>
            </div>
          </div>
        </div>

        <div class="box" v-if="shppingGroupList">
          <h4>주문하신 상품</h4>
          <template v-for="(list, index) in shppingGroupList">
            <div :key="index">
              <div
                class="cont_box"
                v-for="(goods, gidx) in list.goods"
                :key="gidx"
              >
                <table summary="">
                  <colgroup>
                    <col width="80px" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>상품명</td>
                      <td>{{ goods.goods_name }}</td>
                    </tr>
                    <tr>
                      <td>판매단가</td>
                      <td>{{ won(goods.sales.result_price) }}원</td>
                    </tr>
                    <tr>
                      <td>수량</td>
                      <td>{{ parseInt(goods.ea) }}개</td>
                    </tr>
                    <tr v-if="goods.option1">
                      <td>옵션({{ goods.title1 }})</td>
                      <td>{{ goods.option1 }}</td>
                    </tr>
                    <tr v-if="goods.option2">
                      <td>옵션({{ goods.title2 }})</td>
                      <td>{{ goods.option2 }}</td>
                    </tr>
                    <tr v-if="goods.option3">
                      <td>옵션({{ goods.title3 }})</td>
                      <td>{{ goods.option3 }}</td>
                    </tr>
                    <tr v-if="goods.option4">
                      <td>옵션({{ goods.title4 }})</td>
                      <td>{{ goods.option4 }}</td>
                    </tr>
                    <tr v-if="goods.option5">
                      <td>옵션({{ goods.title5 }})</td>
                      <td>{{ goods.option5 }}</td>
                    </tr>
                    <tr
                      v-for="(sub, ssidx) in goods.cart_suboptions"
                      :key="`${ssidx}_option1`"
                    >
                      <td>추가({{ sub.suboption_title }})</td>
                      <td>
                        {{ sub.suboption }} {{ sub.ea }}개
                        {{ won(sub.sales.sale_price) }}원
                      </td>
                    </tr>
                    <tr>
                      <td>배송비</td>
                      <td>
                        {{ won(shippingGroupCost(goods.shipping_group)) }}원
                      </td>
                    </tr>
                    <tr>
                      <td>주문금액</td>
                      <td>
                        {{
                          won(
                            parseInt(goods.tot_result_price) +
                              (shippingGroupCost(goods.shipping_group) > 0
                                ? parseInt(
                                    shppingGroupList[index].grp_shipping_price
                                  )
                                : 0)
                          )
                        }}원
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>

        <div class="box">
          <h4>사용 가능한 내 램 포인트</h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>램 포인트</td>
                  <td>{{ won(parseInt(myInfo.ramm_point)) }}P</td>
                </tr>
                <tr>
                  <td>환산액(원)</td>
                  <td>{{ convertPoint(parseInt(myInfo.ramm_point)) }}원</td>
                </tr>
                <tr class="point">
                  <td>사용</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      @input="setPoint($event)"
                      maxlength="10"
                    /><span>원</span
                    ><router-link to="" @click.native="allUsePoint($event)"
                      >전액사용</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="box">
          <h4>결제내역</h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>주문금액</td>
                  <td>
                    {{ won(paymentData.total - paymentData.total_sale) }}원
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>배송비</td>
                  <td>
                    {{ won(totalShippingCost) }}원
                    {{
                      shppingCost["shipping_cost_detail"] &&
                      parseInt(shppingCost["shipping_cost_detail"]["add"])
                        ? `(+${won(
                            shppingCost["shipping_cost_detail"]["add"]
                          )})`
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>포인트 사용</td>
                  <td>{{ won(usePoint) }}원</td>
                </tr>
                <tr>
                  <td>결제 수단</td>
                  <td>
                    <select name="payment_type" id="">
                      <option value="card">카드/간편결제</option>
                      <!-- <option value="bank">무통장</option> -->
                      <!-- <option value="card">카드</option> -->
                      <!-- <option value="escrow_virtual">가상계좌(에스크로)</option> -->
                    </select>
                  </td>
                </tr>
                <tr class="final">
                  <td>결제금액</td>
                  <td>
                    <span>{{ won(totalCost) }}원</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="sect_bottom">
        <p>
          개인정보 제공 동의 :
          <span style="font-weight: 600">{{ providerNames }}</span
          >&nbsp;|&nbsp;<router-link to="" @click.native="showPolicy"
            >상세보기</router-link
          >
        </p>
        <div class="box">
          <h4>주문을 확인했으며 개인정보 제공에 동의합니다.</h4>
          <div class="yellow">
            <router-link to="" @click.native="payment">결제하기</router-link>
          </div>
          <!-- <div class="green">
            <a href="#"
              ><img src="@/assets/images/sub/naver_ico.png" alt="" />구매하기</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <OrderPolicy class="policy" v-if="isPolicy" @showPolicy="showPolicy" />
    <form id="pg_form" method="post" action="/lg_ramm/auth" target="tar_opener">
      <input type="hidden" name="order_seq" value="" />
      <input type="hidden" name="goods_name" value="" />
      <input type="hidden" name="goods_seq" value="" />
      <input type="hidden" name="mobilenew" value="" />
    </form>
    <iframe
      id="pay_frame"
      name="tar_opener"
      frameborder="0"
      width="100%"
      height="100%"
      scrolling="auto"
      class="pay_ments"
      style="display: none"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderPolicy from "@/components/policy/Order";
export default {
  data() {
    return {
      isTimer: false,
      timer: "",
      IsShipMsg: true,
      category: this.$route.params.category || null,
      goodsSeq: this.$route.params.goodsSeq || null,
      mode: this.$route.params.mode || null,
      reviewSeq: this.$route.query.review_seq || null,
      usePoint: 0,
      isChange: false,
      cellphone: null,
      authNumber: null,
      isShippList: true,
      isShippLestest: true,
      isShippListView: false,
      tabType: 0,
      checkIdx: 0,
      isPolicy: false,
      memoContent: null,
      agree: "N",
      pointPer: 10,
      frameShow: false,
      totShppingCost: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: false,
    });
  },
  mounted() {
    this.getSettle();
    if (this.$store.state.common.referer) {
      if (
        this.$store.state.common.referer.path === "/mypage/shipping_address_reg"
      ) {
        this.$store.dispatch("common/setReferer", null);
        this.isShippListView = true;
      }
    }
    window.addEventListener("message", this.orderReslut);
  },
  components: {
    OrderPolicy,
  },
  computed: {
    ...mapState("member", {
      mMsg: "msg",
      old_cellphone: "old_cellphone",
      tryCnt: "tryCnt",
      limitCnt: "limitCnt",
    }),
    ...mapState("order", [
      "result",
      "msg",
      "paymentData",
      "completeOrder",
      "shppingCost",
    ]),
    ...mapState("mypage", ["myInfo"]),
    members() {
      return this.paymentData.members;
    },
    shppingGroupList() {
      let shppingGroupList = [];
      if (this.paymentData.shipping_group_list) {
        Object.keys(this.paymentData.shipping_group_list).forEach((key) => {
          shppingGroupList.push(this.paymentData.shipping_group_list[key]);
        });
      }
      return shppingGroupList;
    },
    totalCost() {
      return (
        this.paymentData.total -
        this.paymentData.total_sale +
        this.totalShippingCost -
        this.usePoint
      );
    },
    orgTotalCost() {
      return (
        this.paymentData.total -
        this.paymentData.total_sale +
        this.totalShippingCost
      );
    },
    totalPoint() {
      return Math.floor(parseInt(this.myInfo.ramm_point) / 100);
    },
    totalShippingCost: {
      set(value) {
        this.totShppingCost = value;
      },
      get() {
        // if (this.paymentData.shipping_group_price) {
        //   Object.keys(this.paymentData.shipping_group_price).map((key) =>
        //     Object.keys(this.paymentData.shipping_group_price[key]).map(
        //       (item) =>
        //         (this.totShppingCost += parseInt(
        //           this.paymentData.shipping_group_price[key][item]
        //         ))
        //     )
        //   );
        // }
        return this.totShppingCost;
      },
    },
    providerNames() {
      let providerNames = [];
      if (this.paymentData.shipping_group_list) {
        Object.keys(this.paymentData.shipping_group_list).map((key) => {
          providerNames.push(
            this.paymentData.shipping_group_list[key].goods[0].provider_name
          );
        });
      }
      return providerNames.join(",");
    },
    latestAddressList() {
      let latestAddressList = [];
      if (this.paymentData.lately_delivery_address) {
        latestAddressList = [...this.paymentData.lately_delivery_address];
      }
      return latestAddressList;
    },
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    authNumberCk: {
      get() {
        return this.authNumber;
      },
      set(authNumber) {
        this.authNumber = authNumber.replace(/[^0-9]/g, "");
      },
    },
    isAuthNumber: {
      get() {
        return this.$store.state.member.isAuthNumber;
      },
      set(isAuthNumber) {
        this.$store.commit("member/updateUserInfo", {
          isAuthNumber,
        });
      },
    },
  },
  methods: {
    showPolicy(check = false) {
      this.isPolicy = !this.isPolicy;
      if (this.isPolicy) {
        document.querySelector("body").style.overflow = "hidden";
        this.$store.dispatch("common/setFooter", {
          viewFooter: false,
        });
      } else {
        document.querySelector("body").style.overflow = "";
        this.$store.dispatch("common/setFooter", {
          viewFooter: true,
        });
        if (check) {
          this.agree = "Y";
        } else {
          this.agree = "N";
        }
      }
    },
    goBack() {
      if (this.mode !== "direct") {
        this.$router.push(`/mypage/my_cart`);
      } else {
        this.$router.push(
          `/market_goods_detail/${this.category}/${this.goodsSeq}`
        );
      }
    },
    addressChange() {
      const inpuEl = document.querySelector("input[name=address]:checked");

      if (!inpuEl) return false;

      const index = inpuEl.dataset.index;
      const type = inpuEl.dataset.type;

      let addr = {};
      if (parseInt(type) === 1) {
        addr.recipient_address =
          this.paymentData.shipp_address_list[index].recipient_address;
        addr.recipient_phone =
          this.paymentData.shipp_address_list[index].recipient_phone;
        addr.recipient_address_detail =
          this.paymentData.shipp_address_list[index].recipient_address_detail;
        addr.recipient_zipcode =
          this.paymentData.shipp_address_list[index].recipient_zipcode;
        addr.recipient_user_name =
          this.paymentData.shipp_address_list[index].recipient_user_name;
        addr.recipient_cellphone =
          this.paymentData.shipp_address_list[index].recipient_cellphone;
      } else {
        addr.recipient_address =
          this.latestAddressList[index].recipient_address;
        addr.recipient_phone = this.latestAddressList[index].recipient_phone;
        addr.recipient_address_detail =
          this.latestAddressList[index].recipient_address_detail;
        addr.recipient_zipcode =
          this.latestAddressList[index].recipient_zipcode;
        addr.recipient_user_name =
          this.latestAddressList[index].recipient_user_name;
        addr.recipient_cellphone =
          this.latestAddressList[index].recipient_cellphone;
      }
      // console.log(addr);
      this.$store.dispatch("order/changeAddress", addr);
      this.changeAddr();
      this.tabType = type;
      this.checkIdx = index;

      this.getShppingCost();
    },
    goRegShippAddr() {
      this.$store.dispatch("common/setReferer", {
        path: this.$route.path,
      });
      let addReg = "/mypage/shipping_address_reg";
      if (this.reviewSeq) {
        addReg += `?review_seq=${this.reviewSeq}`;
      }
      this.$router.push(addReg);
      return false;
    },
    changeShippAddress(type, event) {
      [...document.querySelectorAll(".add_tab li")].map((li) =>
        li.classList.remove("on")
      );
      event.target.closest("li").classList.add("on");
      if (parseInt(type) === 1) {
        this.isShippLestest = false;
        this.isShippList = true;
      } else {
        this.isShippList = false;
        this.isShippLestest = true;
      }
    },
    setNumber(event) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    change() {
      this.cellphoneCk = "";
      this.$store.commit("member/updateCellphoneReset");
      this.authNumberCk = "";
      this.isChange = !this.isChange;
    },
    changeAddr() {
      this.isShippListView = !this.isShippListView;

      if (this.isShippListView) {
        this.$nextTick(function () {
          if (parseInt(this.tabType) !== 0) {
            [...document.querySelectorAll(".add_tab li")].map((li) =>
              li.classList.remove("on")
            );
            if (parseInt(this.tabType) === 1) {
              document
                .querySelector(".add_tab li:nth-child(1)")
                .classList.add("on");
              this.isShippLestest = false;
              this.isShippList = true;
              this.$nextTick(function () {
                document.querySelectorAll("input[name=address]")[
                  parseInt(this.checkIdx)
                ].checked = true;
              });
            } else {
              document
                .querySelector(".add_tab li:nth-child(2)")
                .classList.add("on");
              this.isShippList = false;
              this.isShippLestest = true;
              this.$nextTick(function () {
                document.querySelectorAll("input[name=address]")[
                  parseInt(this.checkIdx)
                ].checked = true;
              });
            }

            // console.log(
            //   document.querySelectorAll("input[name=address]")[this.checkIdx]
            // );
            return false;
          }
        });
      }

      this.isShippLestest = false;
      this.isShippList = true;
    },
    won(str) {
      return this.$won(str);
    },
    setPoint(event) {
      let point = event.target.value.replace(/[^0-9]/g, "");
      let unCommaPoin = point.replace(/,/g, "");

      if (parseInt(unCommaPoin) > parseInt(this.orgTotalCost)) {
        if (parseInt(unCommaPoin) > parseInt(this.totalPoint)) {
          this.$toast.default(
            "결제금액 / 보유하고 있는 램 포인트 보다 많은  포인트는 사용하실 수 없습니다."
          );
        } else {
          this.$toast.default(
            "결제금액 보다 많은 포인트는 사용하실 수 없습니다."
          );
        }
        event.target.value = this.won(this.orgTotalCost);
        this.usePoint = this.orgTotalCost;
      } else {
        if (parseInt(unCommaPoin) > parseInt(this.totalPoint)) {
          this.$toast.default(
            "보유하고 있는 최대 램 포인트를 초과하실 수 없습니다."
          );
          event.target.value = this.won(this.totalPoint);
          this.usePoint = this.totalPoint;
        }
        event.target.value = this.won(unCommaPoin);
        this.usePoint = unCommaPoin;
      }
    },
    shippingGroupCost(key) {
      let cost = 0;
      Object.keys(this.paymentData.shipping_group_price[key]).map(
        (item) =>
          (cost += parseInt(this.paymentData.shipping_group_price[key][item]))
      );
      return cost;
    },
    allUsePoint(event) {
      if (parseInt(this.totalPoint) >= parseInt(this.orgTotalCost)) {
        event.target.closest(".point").querySelector("input").value = this.won(
          this.orgTotalCost
        );
        this.usePoint = this.orgTotalCost;
        return false;
      }
      this.usePoint = this.totalPoint;
      event.target.closest(".point").querySelector("input").value = this.won(
        this.totalPoint
      );
    },
    convertPoint(point) {
      return parseInt(point / 100) > 0 ? this.won(parseInt(point) / 100) : 0;
    },
    shipMessage(event) {
      event.target.value ? (this.IsShipMsg = false) : (this.IsShipMsg = true);
    },
    async getSettle() {
      await this.$store.dispatch("order/getSettle", { mode: this.mode });
      if (Object.keys(this.paymentData) <= 0) {
        this.$toast.default("주문 가능한 상품이 없습니다.");
        this.$router.push("/market_main_list");
        return false;
      }
      if (!this.result) {
        this.$toast.default(this.msg);
        return false;
      }
      this.getShppingCost();
    },
    async getShppingCost() {
      await this.$store.dispatch("order/calculateShippingCost", {
        recipient_address_street:
          this.paymentData.members.default_address.address_street,
        recipient_address:
          this.paymentData.members.default_address.address_street,
        mode: this.mode,
      });

      console.log(this.shppingCost["total_shipping_price"]);
      this.totalShippingCost = parseInt(
        this.shppingCost["total_shipping_price"]
      );
    },
    async getAuthNumber() {
      const phoenReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
      if (!phoenReg.test(this.cellphone)) {
        this.$toast.default("'-' 없는 휴대폰 번호 형식으로 입력해주세요.");
        this.cellphone = null;
        return false;
      }
      if (!this.isAuthNumbe) {
        if (this.old_cellphone === this.cellphone) {
          this.$toast.default(
            `동일한 번호로 이미 인증번호를 발송 했습니다.
          인증번호 요청시도(${this.tryCnt}/${this.limitCnt})`
          );
          return false;
        }
      }

      this.isAuthNumber = false;
      clearInterval(this.timer);
      this.isTimer = false;

      await this.$store.dispatch("member/getAuthNumber", {
        cellphone: this.cellphone,
      });
      if (this.isAuthNumber) {
        if (this.isTimer === false) {
          this.startTimer();
        }
        this.$toast.default(this.mMsg);
      } else {
        this.$toast.default(this.mMsg);
      }
    },
    startTimer() {
      this.isTimer = true;
      const minEl = this.$refs.timer_min;
      const secEl = this.$refs.timer_sec;
      let sec = 59;
      let min = 2;
      let sec_str = "";
      this.timer = setInterval(() => {
        if (sec == 0) {
          sec = 59;
          if (min != 0) {
            min = min - 1;
          }
        } else {
          sec = sec - 1;
          sec_str = sec.toString();
          if (sec < 10) {
            sec_str = `0${sec.toString()}`;
          }
        }
        minEl.textContent = min;
        secEl.textContent = sec_str;
        if (min == 0 && sec == 0) {
          clearInterval(this.timer);
          this.isAuthNumber = false;
        }
      }, 1000);
    },
    async updateInfo() {
      const name = document.querySelector("input[name='tmp_name'").value;
      const phone = this.cellphoneCk;
      const tmp_authnumber = this.authNumberCk;
      const authNumber = this.isAuthNumber;

      if (name.replace(/\s/g, "").length <= 0) {
        this.$toast.default("변경할 주문자 이름을 입력하세요.");
        document.querySelector("input[name='tmp_name'").focus();
        return false;
      }

      if (phone.replace(/\s/g, "").length <= 0) {
        this.$toast.default("변경할 주문자 휴대폰 번호를 입력하세요.");
        document.querySelector("input[name='cellphone'").focus();
        return false;
      }
      if (!this.isAuthNumber) {
        this.$toast.default("휴대폰 번호 인증을 요청해주세요.");
        return false;
      }
      if (!tmp_authnumber) {
        this.$toast.default("인증번호를 입력하세요.");
        document.querySelector("input[name='tmp_authnumber'").focus();
        return false;
      }

      const data = {
        name,
        phone,
        tmp_authnumber,
      };

      await this.$store.dispatch("order/orderMemberUpdate", data);
      this.$toast.default(this.msg);
      if (this.result) {
        this.change();
      }
    },
    async payment() {
      const zipcode = this.paymentData.members.default_address.zipcode;
      if (!zipcode) {
        this.$toast.default("배송지 주소를 등록해주세요.");
        return false;
      }
      if (zipcode.replace(/\s/gi, "").length <= 0) {
        this.$toast.default("배송지 주소를 등록해주세요.");
        return false;
      }
      const memo = document.querySelector("select[name=ship_message]").value;
      if (!memo && !this.memoContent) {
        this.$toast.default("배송 요청 사항을 선택 또는 입력해주세요.");
        return false;
      }

      let payment_type = document.querySelector(
        "select[name=payment_type]"
      ).value;
      if (!payment_type) {
        this.$toast.default("결제수단을 선택해주세요.");
        return false;
      }

      if (parseInt(this.usePoint) > parseInt(this.totalPoint)) {
        this.$toast.default(
          "보유하고 있는 최대 램 포인트를 초과하실 수 없습니다."
        );
        return false;
      }
      if (parseInt(this.usePoint) > parseInt(this.orgTotalCost)) {
        this.$toast.default(
          "결제금액 보다 많은 포인트는 사용하실 수 없습니다."
        );
        return false;
      }
      if (parseInt(this.usePoint) >= parseInt(this.orgTotalCost)) {
        payment_type = "bank";
      }

      if (payment_type === "card") {
        if (parseInt(this.totalCost) < 100) {
          this.$toast.default("최소 결제금액은 100원 이상 입니다.");
          return false;
        }
      }

      // if (this.agree !== "Y") {
      //   this.$toast.default("개인정보 제3자 제공 동의에 동의해주세요");
      //   this.showPolicy();
      //   return false;
      // }
      // if (this.usePoint > 0) {
      //   if (this.usePoint % this.pointPer !== 0) {
      //     this.$toast.default(
      //       `램 포인트는 ${this.pointPer}원 단위로 사용가능합니다.`
      //     );
      //     return false;
      //   }
      // }

      const payment = this.paymentData;
      let formData = new FormData();
      //기존 값
      formData.append("mode", this.mode);
      formData.append("order_version", "2.0"); //기본
      formData.append("delivery_coupon", ""); //배송 할인 쿠폰 사용안함
      formData.append("coupon_sale", ""); //상품 할인 쿠폰 번호 사용안함
      formData.append("member_seq", ""); //회원 번호 일반결제는 사용안함
      formData.append("person_seq", ""); //개인 결제 인가 확인하는 번호 사용안함
      formData.append("enuri", ""); //개인 결제 창에서 만 사용되는 할인 사용안함
      formData.append("ordersheet_coupon_download_seq", ""); //주문서에서 확인 하는 쿠폰번호의 번호 사용안함
      if (this.review_seq) {
        formData.append("review_seq", this.reviewSeq);
      }
      payment.cart_list.map((list) => {
        formData.append(
          `coupon_download[${list.cart_seq}][${list.cart_option_seq}]`,
          ""
        );
        formData.append(`shippingcoupon_download[${list.shipping_group}]`, "");
      });
      formData.append("chkQuickAddress", "often");

      //배송지 가능 여부
      formData.append("ship_possible", payment.$ship_possible ? "N" : "Y");

      formData.append("address_nation", payment.ini_info.nation);
      formData.append("address_nation_key", payment.ini_info.nation_key);

      //받는 사람 이름
      formData.append(
        "recipient_user_name",
        payment.members.default_address.user_name
      );
      //받는 사람 우편번호
      formData.append(
        "recipient_new_zipcode",
        payment.members.default_address.zipcode
      );
      //받는 사람 주소유형
      formData.append(
        "recipient_address_type",
        payment.members.default_address.address_type
      );
      //받는 사람 길 주소
      formData.append(
        "recipient_address_street",
        payment.members.default_address.address_street
      );
      //받는 사람 지번주소 //전부 도로명 주소
      formData.append(
        "recipient_address",
        payment.members.default_address.address_street
      );
      //받는 사람 지번주소
      formData.append(
        "recipient_address_detail",
        payment.members.default_address.address_detail
      );

      //해외 배송 사용안함
      formData.append("international_address", "");
      formData.append("international_town_city", "");
      formData.append("international_county", "");
      formData.append("international_postcode", "");

      formData.append(
        "international_country",
        payment.members.default_address.nation
      );

      //받는 사람 휴대 전화 번호
      formData.append(
        "recipient_cellphone[]",
        payment.members.default_address.cellphone1 || ""
      );
      formData.append(
        "recipient_cellphone[]",
        payment.members.default_address.cellphone2 || ""
      );
      formData.append(
        "recipient_cellphone[]",
        payment.members.default_address.cellphone3 || ""
      );

      //받는 전화 번호
      let phone = [];
      if (payment.members.default_address.phone) {
        phone = payment.members.default_address.phone.split("-");
      }
      formData.append("recipient_phone[]", phone[0] || "");
      formData.append("recipient_phone[]", phone[1] || "");
      formData.append("recipient_phone[]", phone[2] || "");

      //메모
      formData.append("memo", memo || this.memoContent);
      formData.append("each_memo", memo || this.memoContent);
      //------------------------------------------------------

      //주문자 정보
      formData.append("order_zipcode[]", "");
      formData.append("order_zipcode[]", "");

      formData.append("order_address_type", "");
      formData.append("order_address", "");
      formData.append("order_address_street", "");
      formData.append("order_address_detail", "");
      formData.append("order_user_name", payment.members.user_name);
      formData.append("order_cellphone[]", payment.members.cellphone1 || "");
      formData.append("order_cellphone[]", payment.members.cellphone2 || "");
      formData.append("order_cellphone[]", payment.members.cellphone3 || "");

      //주문자 전화번호
      let order_phone = [];
      if (payment.members.phone) {
        order_phone = payment.phone.split("-");
      }
      formData.append("order_phone[]", order_phone[0] || "");
      formData.append("order_phone[]", order_phone[1] || "");
      formData.append("order_phone[]", order_phone[2] || "");
      formData.append("order_email", payment.members.email);

      formData.append("cartpromotioncode", ""); //사용 안함

      //램 포인트
      formData.append("emoney_view", this.usePoint);
      formData.append("emoney", this.usePoint);
      formData.append("emoney_all", "");

      //결제수단
      formData.append("payment", payment_type);
      formData.append("depositor", payment.members.user_name); //무통장을 사용하지 않을 것 이지만 테스트때 사용자 이름으로
      formData.append(
        "bank",
        `${payment.bank[0].bank} ${payment.bank[0].account} 예금주:${payment.bank[0].bankUser}`
      ); //무통장 사용하지 않지만 테스트 때 리뷰앤 계좌
      formData.append("typereceiptuse", "0"); //무통장 사용안하지만 테스트때 현금연수증/세금계산서 발급유무 0미발급 1발급
      formData.append("cuse", "0"); //소득곰제여부 무통장 사용안함

      formData.append("creceipt_number[]", ""); //소득공제용 휴대폰번호1
      formData.append("creceipt_number[]", ""); //소득공제용 휴대폰번호2

      formData.append("sales_email", ""); //소득고제용 이메일

      //세금 계산서 발행
      formData.append("co_name", ""); //상호명
      formData.append("busi_no", ""); //사업자등록번호
      formData.append("co_ceo", ""); //대표자명
      formData.append("co_status", ""); //업태
      formData.append("co_type", ""); //업종
      formData.append("co_new_zipcode", ""); //우편번호
      formData.append("co_address_type", ""); //주소명 타입
      formData.append("co_address", ""); //주소
      formData.append("co_address_street", ""); //도로명 주소
      formData.append("co_address_detail", ""); //상세 주소
      formData.append("person", ""); //담당자명
      formData.append("email", payment.members.email); //이메일
      formData.append("phone", ""); //연락처

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // return false;
      // 다시 정보동의 하기 할 수 있게.
      this.agree = false;
      await this.$store.dispatch("order/payment", {
        formData,
        mode: this.mode,
      });

      if (this.result) {
        if (payment_type === "bank") {
          await this.$store.dispatch("order/orderMail", {
            order_seq: this.completeOrder,
          });
          this.$toast.default(this.msg);
          this.$router.push("/mypage/payment_list");
        } else {
          this.$store.dispatch("common/setLoading", true);
          const pgdata = this.completeOrder;
          const form = document.forms.pg_form;
          form.elements.order_seq.value = pgdata.order_seq;
          form.elements.goods_name.value = pgdata.goods_name;
          form.elements.goods_seq.value = pgdata.goods_seq;
          form.elements.mobilenew.value = "y";
          form.submit();
          document.querySelector("#pay_frame").style.display = "block";
        }
      } else {
        this.$toast.default(this.msg);
      }
    },
    async orderReslut(event) {
      if (event.data.result) {
        console.log(event.data);
        document.querySelector("#pay_frame").style.display = "none";
        this.$toast.default(event.data.msg);
        if (event.data.result === "done") {
          await this.$store.dispatch("order/orderMail", {
            order_seq: event.data.order_seq,
          });
          this.$router.push("/mypage/payment_list");
        }
      }
      this.$store.dispatch("common/setLoading", false);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener("message", this.orderReslut);
  },
};
</script>

<style lang="scss">
.timer {
  position: absolute;
  top: 50%;
  right: 95px;
  transform: translateY(-50%);
  color: $info-txt;
  font-size: 14px;
}
#timer_min {
  float: none;
}
#timer_sec {
  float: none;
}
.policy {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  overflow: scroll;
}
.sel_box {
  &.none {
    text-align: center;
    div {
      border-bottom: none !important;
    }
  }
}
.pay_ments {
  margin: 0px auto 50px auto;
  position: fixed;
  z-index: 9999999999;
  top: 0;
}
</style>
