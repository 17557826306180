<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      주문 및 결제하기<router-link to="" @click.native="goBack()"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_34">
      <h4>
        개인정보 제공 동의<span>필수<font color="red">Ⅴ</font></span>
      </h4>
      <div class="box">
        <p>
          램 회원이 램 가게, 리뷰 거래소 또는 램 제휴사의 상품 및 서비스를
          구매하실 경우, 리뷰앤메이크머니는 거래 당사자 간의 거래이행을 위해
          필요한 최소한의 개인정보를 아래와 같이 판매자 또는 제휴사에 제공하고
          있습니다.
        </p>
        <p>&nbsp;</p>
        <p>
          1. 구매자의 개인정보가 제공되는 대상은 상품/서비스 판매자 또는
          제휴사입니다. 해당 정보는 판매자 또는 제휴사에 의해 배송사,
          위탁판매업체 등에 제공될 수 있습니다.
        </p>
        <p>
          2. 판매자 또는 제휴사에 제공되는 개인정보는 구매자의 이름, 램 아이디,
          전화번호, 상품구매정보, 결제수단, 결제내역, 상품 수령인
          정보(배송지란의 정보)입니다.
        </p>
        <p>
          3. 판매자 또는 제휴사에 제공되는 개인정보는 판매자와 구매 자 간의
          원활한 거래 진행, 상품 또는 서비스의 정확한 배송, 오배송이나 물품의
          하자 등에 대한 고객 불만 처리 등을 위 해 사용됩니다.
        </p>
        <p>
          4. 판매자 또는 제휴사는 제공받은 개인정보를 위 3항의 목적이 달성될
          때까지 보존, 이용합니다. 목적을 달성한 후에는 이를 즉시 삭제합니다(단,
          관계법령의 규정에 의해 일정 기간 이를 보존해야 할 경우 판매자 또는
          제휴사는 해당 기간 만큼 보관 후 이를 삭제합니다).
        </p>
        <br />
        <p>
          구매자는 위 개인정보 제공 동의를 거부할 수 있습니다. 그러나 거부할
          경우, 해당 상품 또는 서비스를 구매하실 수 없습니다.
        </p>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="goBack(true)">확인</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack(check = false) {
      this.$emit("showPolicy", check);
    },
  },
};
</script>

<style></style>
